import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ExhibitResponse, getEvent } from "../../../store/events/actions";
import {
  isLoading,
  selectCurrentOption,
  selectEvent,
  selectEventErrors,
} from "../../../store/events/selectors";
import { useAppDispatch } from "../../../store";
import PublicLayout from "../../../components/PublicLayout";
import PublicAuctionList from "../../../components/Public/PublicAuctionList";
import FullScreenLoading from "../../../components/Loading/FullScreenLoading";
import PublicEventMain from "../../../components/Public/PublicEventMain";
import PublicOptionsSelector from "../../../components/Public/PublicOptionsSelector";
import {
  clearError,
  PublicOptions,
  resetState,
} from "../../../store/events/reducer";
import PublicHowToBid from "../../../components/Public/PublicHowToBid";
import PublicContactUs from "../../../components/Public/PublicContactUs";

const PublicEvent = ({ id }) => {
  const dispatch = useAppDispatch();
  const loading = useSelector((state) => isLoading(state));
  const event = useSelector((state) =>
    selectEvent(state)
  ) as ExhibitResponse | null;
  const selectedOption = useSelector((state) => selectCurrentOption(state));
  const eventError = useSelector((state) => selectEventErrors(state));

  useEffect(() => {
    if (eventError) {
      dispatch(clearError());
      navigate("/404/");
    }
  }, [eventError]);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      // clear local storage
      localStorage.clear();
    }
    dispatch(clearError());
    dispatch(resetState());
    if (id) {
      // fetch auction
      dispatch(getEvent(id));
    } else {
      navigate("/404/");
    }
  }, []);

  const getEventLink = () => {
    if (event && event.data && event.data.slug) {
      return `/p/events/${
        event && event.data && event.data.slug ? event.data.slug : ""
      }/`;
    }
    return "";
  };

  return (
    <PublicLayout
      whiteBackground
      description={event && event.data ? event.data.description : "This 2023 Keep A Breast (KAB) Cast Collection features the casts of 20 San Diego locals including survivors, artists, photographers, musicians, gallery owners and business owners. The completed sculptures represent fragility and strength while serving as a way to start conversations around the serious and important topic of breast cancer. All funds raised will support of KAB's youth-focused advocacy and programming around breast cancer prevention including its free breast self-check mobile app (the Keep A Breast App), available in English, Spanish, and French, as well as its Give Back Grant Program, which provides $500 grants to BIPOC women breast cancer survivors and warriors. Proceeds also benefit KAB’s Check Yourself Program, an ongoing digital and print educational initiative."}
      logo={event && event.data ? event.data.logo_url : ""}
      title={event && event.data ? event.data.name : "Keep A Breast X Fall Brewing Co. 2023 San Diego Breast Cast Collection"}
      image={"https://bidddyapp.imgix.net/p/files/exhibit/283da3f3c39a42b48a3be50f454a7ddb.jpeg"}
      username={event && event.data ? event.data.username : ""}
      link={getEventLink()}
    >
      <PublicOptionsSelector />
      {event && event.data && selectedOption === PublicOptions.AuctionItems && (
        <PublicEventMain />
      )}
      {event && event.data && selectedOption === PublicOptions.AuctionItems && (
        <PublicAuctionList />
      )}
      {event && event.data && selectedOption === PublicOptions.HowToBid && (
        <PublicHowToBid />
      )}
      {event && event.data && selectedOption === PublicOptions.ContactUs && (
        <PublicContactUs />
      )}
      {loading && <FullScreenLoading />}
    </PublicLayout>
  );
};

export default PublicEvent;
