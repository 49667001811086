import React, { useEffect, useState } from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { useSelector } from "react-redux";
import { ExhibitResponse } from "../../store/events/actions";
import { selectEvent } from "../../store/events/selectors";
import styles from "./PublicMainAuction.module.scss";
import { sendToast } from "../../utils/helpers";
import DefaultButton from "../Buttons/DefaultButton";
import { LoginProps, signIn } from "../../store/auth/actions";
import { useAppDispatch } from "../../store";
import CloseButton from "../PageComponents/Dashboard/Auctions/AuctionCreation/internal/CloseButton";
import BidddyLogoSmall from "../Images/BidddyLogoSmall";
import DefaultInput from "../Inputs/DefaultInput";
import GoogleButton from "../Inputs/GoogleButton";
import { baseURL } from "../../utils/axios";
import useAuthHook from "../Hooks/useAuthHook";
import useConfirmBidHook from "../Hooks/useConfirmBidHook";
import { getAuthSuccess } from "../../store/auth/selectors";
import { clearSuccessState } from "../../store/auth/reducer";

const SuccessIcon = () => (
  <svg
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C5.92487 22 1 17.0751 1 11C1 4.92487 5.92487 0 12 0C18.0751 0 23 4.92487 23 11C23 17.0751 18.0751 22 12 22ZM12 20C16.9706 20 21 15.9706 21 11C21 6.02944 16.9706 2 12 2C7.02944 2 3 6.02944 3 11C3 15.9706 7.02944 20 12 20ZM15.2929 7.29289L10 12.5858L7.70711 10.2929L6.29289 11.7071L10 15.4142L16.7071 8.70711L15.2929 7.29289Z"
      fill="#fff"
    />
  </svg>
);

const ShareArrow = () => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0V5.22679C3.45134 5.53518 0 8.93822 0 15.2038V17.618L1.70711 15.9109C3.95393 13.6641 6.69021 12.7227 10 13.0756V18.4075L20.5186 9.20377L10 0ZM12 7.20377V4.40754L17.4814 9.20377L12 14V11.3566L11.1644 11.2174C7.74982 10.6483 4.74443 11.2183 2.20125 12.9203C2.94953 9.05479 5.79936 7.20377 11 7.20377H12Z"
      fill="#F4799E"
    />
  </svg>
);

const THRIVE_IMAGE =
  "https://imgix.cosmicjs.com/234ac590-e10d-11ec-8e11-7b73d7c60456-ThriveImage.png";

const PublicEventMain = () => {
  const event = useSelector((state) =>
    selectEvent(state)
  ) as ExhibitResponse | null;
  const dispatch = useAppDispatch();
  const [showBiddingModal, setShowBiddingModal] = useState(false);
  const [email, setEmail] = useState("");
  const [googleParams, setGoogleParams] = useState("");

  const [authenticated] = useAuthHook();
  useConfirmBidHook({ authenticated });
  const success = useSelector((state) => getAuthSuccess(state));
  useEffect(() => {
    const params = {
      redirect: `${window.location.href.split("?")[0]}`,
      type: "bidder",
      bid: null,
    };
    const encodedURI = encodeURI(JSON.stringify(params));

    setGoogleParams(`${baseURL}/auth/google?state=${encodedURI}`);
  }, [])

  useEffect(() => {
    if (success) {
      // TODO: display on modal "CHECK YOUR INBOX"
      sendToast(
        <>
          <p>
            <strong>Successfully submitted email.</strong>
          </p>
          <p>Please check your email for further instructions.</p>
        </>,
        {
          type: "success",
          icon: <SuccessIcon />,
          className: styles.successToastContainer,
        }
      );
      dispatch(clearSuccessState());
      setShowBiddingModal(false);
    }
  }, [success]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleConfirmEmail = () => {
    // validate email
    if (!validateEmail(email)) {
      return sendToast("Please enter a valid email address", { type: "error" });
    }
    // If providing the bid we need the auction id.
    // We need to associate the bid to the auction
    // I need to handle to handle minimum bid increment

    const userData = {
      bidder: {
        email,
        redirect: window.location.href.split("?")[0],
      },
      bid: null,
    } as LoginProps;
    setShowBiddingModal(false);
    return dispatch(signIn(userData));
  };

  const handleShare = () => {
    const url = `https://bidddy.com/p/events/${event.data.slug}/`;
    if (navigator.canShare) {
      navigator.share({
        title: event.data.name,
        text: "Check out this event!",
        url,
      });
    } else {
      const copyText = url;
      // copy to clipboard
      navigator.clipboard
        .writeText(copyText)
        .then(() =>
          // console.log('Async: Copying to clipboard was successful!');
          sendToast("Copied Link to Clipboard!", { type: "success" })
        )
        .catch(() => {
          console.error("Error copying text");
        });
    }
  };

  const navigateToRegistration = () => {
    if (typeof window !== 'undefined') {
      // Keep A Breast
      // window.location.href = 'https://buy.stripe.com/eVabJub2ggYpcVO9AA';
      // TEST
      
      // const URL = process.env.NODE_ENV === 'development'
      //   ? 'https://buy.stripe.com/test_cN24kiekJ3cMaK4dQQ'
      //   : 'https://buy.stripe.com/test_cN24kiekJ3cMaK4dQQ';
        // TODO: add this back
        // : 'https://buy.stripe.com/eVabJub2ggYpcVO9AA';
      window.open(event && event.data && event.data.signup_url ? event.data.signup_url : 'https://buy.stripe.com/eVabJub2ggYpcVO9AA', '_blank');
    }
  }

  return (
    <>
      <div className={styles.publicMainContainer}>
        <div className={styles.publicMainImageContainer}>
          <>
            <Imgix
              src={`${
                (event && event.data && event.data.profile_url) || THRIVE_IMAGE
              }`}
              height={480}
              width={519}
              htmlAttributes={{
                onError: ({ currentTarget }) => {
                  currentTarget.onError = null;
                  currentTarget.src = null;
                },
                alt: "Public Event Image",
                src: `${
                  (event && event.data && event.data.profile_url) || THRIVE_IMAGE
                }`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </>
        </div>
        <div className={styles.publicMainContentContainer}>
          <h4 className={styles.user}>
            <a
              target="_blank"
              rel="noreferrer nofollow noopener"
              href={`http://instagram.com/${event.data.username}`}
            >
              @{event && event.data && event.data.username}
            </a>
          </h4>
          <h1 className={styles.eventTitle}>
            {(event && event.data && event.data.name) || "Made by Toronto"}
          </h1>
          <h2 className={styles.eventSubTitle}>Live on Instagram</h2>
          <h5 className={styles.eventDate}>
            {event && event.data && event.data.date_range}
          </h5>
          <p className={styles.auctionDescription}>
            {event && event.data && event.data.description}
          </p>
          
          <div className={styles.actionsContainer}>
            {!authenticated && (
              <>
                <DefaultButton
                  outlined
                  extraClasses={"min-w-0 shadow-none w-[150px]"}
                  title="Log In"
                  onClick={() => setShowBiddingModal(true)}
                />
                <DefaultButton
                  title="Register For Bidding"
                  onClick={() => navigateToRegistration()}
                />
              </>
            )}
            <div className={styles.bid} />
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => handleShare()}
              className={styles.share}
              onClick={() => handleShare()}
            >
              <ShareArrow />
              <p>TELL A FRIEND</p>
            </div>
          </div>
        </div>
      </div>
      {showBiddingModal && (
        <div className="fixed bg-opacity-70 bg-black inset-0 z-10">
          <div className="w-full h-full lg:w-1/2 lg:max-w-md bg-white lg:h-auto lg:rounded-lg shadow-sm mx-auto lg:mt-12 flex flex-col justify-center items-center p-10 relative">
            {/* Bidddy Logo here */}
            <CloseButton
              onClick={() => setShowBiddingModal(false)}
              extraClasses={styles.topCloseButton}
            />
            <BidddyLogoSmall />
            <h3 className="text-base text-bidddyPink font-inter font-bold mt-8">
              Almost there!
            </h3>
            <h2 className="text-2xl text-bidddyAltGray mb-2">
              Confirm your email
            </h2>
            <p className="mb-4">
              {/* To complete your bid of{" "}
              {getCurrencyLocaleValue(
                publicAuction.data.auction.currency,
                Number(bidAmount)
              )} */}
              In order to bid on an auction we need to make sure you are registered,
              as we will need to know who to contact if you win! Enter your email and
              we&apos;ll send you a magic confirmation link.
            </p>
            {/* TODO: add on enter functionality here */}
            <DefaultInput
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <DefaultButton
              extraClasses={styles.confirmButton}
              title="Confirm"
              onClick={() => handleConfirmEmail()}
              disabled={!email}
            />
            <p className="mt-4">OR</p>
            <GoogleButton className={styles.googleButton} href={googleParams}>
              Confirm with Google
            </GoogleButton>
          </div>
        </div>
      )}
    </>
  );
};

export default PublicEventMain;
